@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&family=Shizuru&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box !important;
}

select:required:invalid {
  color: gray;
}
/* option[value=""][disabled] {
  color: gray;

} */
option {
  color: black;
}

a:-webkit-any-link {
  color: #666666;
}

.MuiTabs-indicator {
  background-color: #ffffff !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(54, 54, 54);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
  width: 15px;
}

/* Font size for screens between 600px and 960px */
@media screen and (min-width: 600px) and (max-width: 900px) {
  h1 {
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h2 {
    font-size: 20px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h3 {
    font-size: 16px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h4 {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h5 {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h6 {
    font-size: 10px !important;
    font-family: "Poppins", sans-serif !important;
  }
  p {
    font-size: 8px !important;
    font-family: "Poppins", sans-serif !important;
  }
}

/* Font size for laptop */
@media screen and (min-width: 900px) {
  h1 {
    font-size: 26px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h2 {
    font-size: 22px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h3 {
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h4 {
    font-size: 16px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h5 {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h6 {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif !important;
  }
  p {
    font-size: 10px !important;
    font-family: "Poppins", sans-serif !important;
  }
}

/* Font size for large screens */
@media screen and (min-width: 1440px) {
  h1 {
    font-size: 28px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h2 {
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h3 {
    font-size: 20px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h4 {
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h5 {
    font-size: 16px !important;
    font-family: "Poppins", sans-serif !important;
  }
  h6 {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
  }
  p {
    font-size: 12px !important;
  }
}
